.commission-date-table {
  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(0, 0, 0, 0.25);

    img {
      width: 80px;
      height: 80px;
    }
  }

  &__row {
    &:hover {
      .commission-date-table__detail-toggle {
        display: block;
      }
    }
  }

  &__cell {
    cursor: pointer;

    &[data-menu='true'] {
      position: relative;
    }

    &[data-type='td'] {
      &:nth-child(2), &:nth-child(3), &:nth-child(4), {
        justify-content: center;
      }

      &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
        justify-content: right;
      }
    }

    &[data-type='th'] {
      &[data-selected='true'] {
        display: flex;
        flex: 1;
        align-items: center;
        height: 44px;
      }
    }

    &:nth-child(1) {
      width: 10%;
      padding-left: 17px;
    }

    &:nth-child(2) {
      width: 10%;
      text-align: center;
    }

    &:nth-child(3) {
      width: 10%;
      text-align: center;
    }

    &:nth-child(4) {
      width: 10%;
      text-align: center;
    }

    &:nth-child(5) {
      width: 15%;
      text-align: right;
    }

    &:nth-child(6) {
      width: 15%;
      text-align: right;
    }

    &:nth-child(7) {
      width: 15%;
      text-align: right;
    }

    &:nth-child(8) {
      width: 15%;
      text-align: right;
    }
  }

  &__tooltipV2 {
    width: 100%;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.commission-order-table {
  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(0, 0, 0, 0.25);

    img {
      width: 80px;
      height: 80px;
    }
  }

  &__row {
    &:hover {
      .commission-order-table__detail-toggle {
        display: block;
      }
    }
  }

  &__cell {
    cursor: pointer;

    &[data-menu='true'] {
      position: relative;
    }

    &[data-type='td'] {
      &:nth-child(2), &:nth-child(3), &:nth-child(7) {
        justify-content: center;
      }

      &:nth-child(5), &:nth-child(4), &:nth-child(6), &:nth-child(8) {
        justify-content: right;
      }
    }

    &[data-type='th'] {
      &[data-selected='true'] {
        display: flex;
        flex: 1;
        align-items: center;
        height: 44px;
      }
    }

    &:nth-child(1) {
      width: 10%;
      padding-left: 17px;
    }

    &:nth-child(2) {
      width: 10%;
      text-align: center;
    }

    &:nth-child(3) {
      width: 12%;
      text-align: center;
    }

    &:nth-child(4) {
      width: 12%;
      text-align: right;
    }

    &:nth-child(5) {
      width: 12%;
      text-align: right;
    }

    &:nth-child(6) {
      width: 12%;
      text-align: right;
    }

    &:nth-child(7) {
      width: 12%;
      flex: 1;
      text-align: center;
    }

    &:nth-child(8) {
      width: 12%;
      text-align: right;
    }
  }

  &__tooltipV2 {
    width: 100%;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.commission-teacher-table {
  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(0, 0, 0, 0.25);

    img {
      width: 80px;
      height: 80px;
    }
  }

  &__row {
    &:hover {
      .commission-teacher-table__detail-toggle {
        display: block;
      }
    }
  }

  &__cell {
    cursor: pointer;

    &[data-menu='true'] {
      position: relative;
    }

    &[data-type='td'] {

      &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        justify-content: center;
        text-align: center;
      }

      &:nth-child(5), &:nth-child(6), &:nth-child(7) {
        justify-content: right;
        text-align: right;
      }
    }

    &[data-type='th'] {
      &[data-selected='true'] {
        display: flex;
        flex: 1;
        align-items: center;
        height: 44px;
      }
    }

    &:nth-child(1) {
      padding-left: 17px;
    }

    &:nth-child(2) {
      width: 15%;
      text-align: center;
      justify-content: center;
    }

    &:nth-child(3) {
      width: 12%;
      text-align: center;
    }

    &:nth-child(4) {
      width: 12%;
      text-align: center;
    }

    &:nth-child(5) {
      width: 12%;
      text-align: right;
    }

    &:nth-child(6) {
      width: 14%;
      text-align: right;
    }

    &:nth-child(7) {
      width: 14%;
      text-align: right;
    }
  }

  &__tooltipV2 {
    width: 100%;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}