.common-popover-student__selected-action-menu{
  width: 167px;
  padding: 8px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  .common-popover-student__selected-action-menu-item {
    padding: 8px;
    span{
      cursor: pointer;
      &:hover{
        color: #3264FF !important;
      }
    }
  }
}