.product-group-table-body_tooltip {
  display: flex;
  align-items: center;
}

.product-group-table-body {
  &-name {
    flex: 1;
  }

  &-setting {
    width: 7rem;
    justify-content: end;

    span {
      display: flex;
      cursor: pointer;
    }
  }

  &__child {
    .tr__container {
      padding-left: 16px;
    }
  }
}

.parent-category__container {
  cursor: pointer;

  &[data-toggle=false] {
    height: 66px;
    overflow: hidden;
  }

  &[data-toggle=true] {
    height: auto;
    overflow: inherit;
  }

  transition: height 2s;

  .product-group-table-body-setting {
    span {
      &[data-toggle=false] {
        transform: rotate(180deg);
      }

      &[data-toggle=true] {
        transform: rotate(0deg);
      }
    }
  }

}