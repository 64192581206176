.customer-table {
  &__status{
    color: #FF424E !important;
    font-size: 12px !important;
    padding: 4px 12px;
    background: #FFEBEC;
    border-radius: 4px;
    &[data-status=true] {
      padding: 4px 12px;
      background: rgb(236, 244, 254);
      border-radius: 4px;
      color: rgb(11, 116, 229) !important;
      font-size: 12px !important;
    }
  }
  &__loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(0, 0, 0, 0.25);

    img {
      width: 80px;
      height: 80px;
    }
  }

  &__row {
    &:hover {
      .customer-table__detail-toggle {
        display: block;
      }
    }
  }

  &__cell {
    cursor: pointer;

    &[data-menu='true'] {
      position: relative;
    }
    &[data-type='th'] {
      &[data-selected='true'] {
        display: flex;
        flex: 1;
        align-items: center;
        height: 44px;
      }
    }

    &:nth-child(1) {
      flex:1
    }

    &:nth-child(2) {
      width: 10%;
    }

    &:nth-child(3) {
      width: 15%;
      text-align: center;
      justify-content: center;
    }
    &:nth-child(4) {
      width: 10%;
      text-align: center;
      justify-content: center;
    }
    &:nth-child(5) {
      width: 12%;
      text-align: left;
      justify-content: left;
    }
    &:nth-child(6) {
      width: 10%;
      display: block;
    }
    &:nth-child(7) {
      width: 15%;
      text-align: center;
      justify-content: center;
    }

    &:nth-child(8) {
      width: 68px;

      justify-content: flex-end;

      @media screen and (max-width: 1599px) {
        width: 38px;
      }
    }
  }

  &__detail-toggle {
    position: absolute;
    top: 50%;
    right: 42px;

    width: 20px;
    height: 20px !important;
    display: none;

    background: transparent;
    border: none;
    border-radius: 12px !important;

    font-size: 12px !important;
    line-height: 24px !important;

    transform: translateY(-50%) rotate(180deg);

    cursor: pointer;

    &[data-active='true'] {
      display: block !important;

      transform: translateY(-50%) rotate(0deg);
    }

    @media screen and (max-width: 1599px) {
      display: none !important;
    }
  }

  &__selected-action-dropdown {
    position: relative;

    margin-left: 12px;
  }

  &__selected-action-toggle {
    width: 88px;
    padding: 0 !important;

    border-radius: 14px !important;

    font-size: 14px !important;
    font-weight: 500 !important;
  }

  &__selected-action-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;

    width: 100vw;
    height: 100vh;
  }

  &__selected-action-menu {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: 12;

    width: 150px;
    padding: 8px;

    background: #ffffff;
    border-radius: 6px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  }

  &__selected-action-menu-item {
    padding: 8px;

    color: #191d32;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    transition: color 0.25s;

    cursor: pointer;

    &:hover {
      color: #3264FF;
    }
  }

  &__tooltipV2 {
    width: 100%;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.tab-detail-customer {
  &__link-hover {
    color: #1A94FF;

    &:hover {
      color: #1373DB;
    }
  }
}